
import Moralis from "moralis";
import {startMoralis} from "./moralisFunctions";
import {MoralisAppID, MoralisServer} from "../index";
import * as Sentry from "@sentry/react";

export enum Web3LoginType {
    FailTestServer,
    LocalServer,
    BSCTestNet,
    BSCMainNet,
}

async function getCurrentUser() {
    return await Moralis.User.currentAsync();
}

export const initWeb3 = async (): Promise<any> => {
    console.log("running initweb3");
    const loginInfo = {serverUrl: MoralisServer, appId: MoralisAppID};
    try {
        const starting = await startMoralis(loginInfo);
        console.log("MoralisConnected", starting);
    } catch {
        console.error("Moralis connection error. Review account.")
        Sentry.captureMessage("Moralis connection error. Review account.");
    }
}

export const checkConnection = async(): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        const currentUser = Moralis.User.current();
        if (currentUser) {
            console.log('User already logged in.');
            const data = {
                packet: getCurrentUser(),
                code: 200,
                message: "LoggedIn"
            }
            resolve(getCurrentUser());
        } else {
            console.log('this person is not logged');
            const data = {
                packet: null,
                code: 400,
                message: "NotLoggedIn"
            }
            reject(data)
        }
    })

}

