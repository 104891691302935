import React from "react";
import {Routes, Route, Link} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import PrivateSalePage from "./pages/PrivateSalePage";
import {Release, Stage} from "./index";


function App() {
    console.log("website version {"+ Stage +"}:", Release);
    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="CarolinaReaper" element={<PrivateSalePage/>}/>
        </Routes>
    );
}

export default App;
