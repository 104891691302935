import React from "react";

const Footer = () => {
  return (
    <footer className="footer-2">
      <div className="container">
        <div className="row justify-content-center">
          <div className="footer-block">
            <div className="sec-title text-center">
              <h4 className="widget-title">Join us on Social!</h4>
            </div>
            <p>
              Join one or all of our social platforms and keep up with new
              developments.
            </p>
          </div>
          <div className="col-sm-12">
            <div className="socials">
              <ul>
                <li>
                  <a className="telegram" href="https://www.t.me/pepperbird" target="_blank">
                    <i className="fab fa-telegram-plane"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="twitter"
                    href="https://twitter.com/pepperbirdtoken" target="_blank"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                      className="discord"
                      href="https://discord.gg/7FnvFwgfhX" target="_blank"
                  >
                    <i className="fab fa-discord"></i>
                  </a>
                </li>
             <li>
               <a
                   className="linkedin" href="https://linkedin.com/company/pepperbird-finance" target="_blank">
                 <i className="fab fa-linkedin-in"></i>
               </a>
             </li>
           <li>
             <a
                 className="instagram" href="https://instagram.com/pepperbirdtoken" target="_blank">
               <i className="fab fa-instagram"></i>
             </a>
           </li>
              <li>
                <a
                    className="youtube" href="https://m.youtube.com/channel/UC3c55RUJff9QzCOSaXewlDA" target="_blank">
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
             <li>
               <a className="medium" href="https://blog.pepperbird.finance" target="_blank">
                 <i className="fab fa-medium-m"></i>
               </a>
             </li>
              </ul>
            </div>
            <div className="copyrights">© 2022 Pepperbird Finance</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
