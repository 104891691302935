import * as Sentry from "@sentry/react";


const BSCSCANTest = 'https://testnet.bscscan.com/';
const BSCSCANMain = 'https://www.bscscan.com/'

const BSCTestNetwork = 'https://speedy-nodes-nyc.moralis.io/a33fce50b94a01d137e905bf/bsc/testnet';
const BSCMainNetwork = 'https://speedy-nodes-nyc.moralis.io/a33fce50b94a01d137e905bf/bsc/mainnet';

const BSCTestNetworkChainID = 97;
const BSCMainNetworkChainID = 56;
const FAILTEST_MoralisServer = 'https://9pxyn3z6scy9.usemoralis.com:2053/server';
const FAILTEST_MoralisAppId = 'Nm91OAXjcVUNyi4szFfwdEOHiJ2m01WOgVTjkNbe';

const BSCTEST_MoralisServer = 'https://tyvwyexlerrb.usemoralis.com:2053/server';
const BSCTEST_MoralisAppId = 'YgSg8okkaa38ZjSRYHbtcIvkOUB8tTpr2Nt9Cxph';

const BSCMAIN_MoralisServer = 'https://o9qv6umcxbe6.usemoralis.com:2053/server';
const BSCMAIN_MoralisAppId = '0jIiJfHQv3qGiXGmDxbCgCp6tL1cyP6qNey4DaeT';

const PrivateSaleAddressTest = '0xF2108DAF7a177FccF4361d7DE402f77B4e6790A6';
const PrivateSaleAddressMain = '0x63C5756a01974f17fe6f0DC5701c4568d4Da54bD';

export enum ServerState {
    MainNet,
    TestNet,
    TestMainNet,
    LocalHost
}

export interface IServerPackage {
    moralisServer: string;
    moralisAppId: string;
    networkRPC: string;
    networkChainId: number;
    privateSaleContract: string;
    release: string;
    type: string;
    chainLookup: string;
}

export function getServerInfo(serverState: ServerState, release: string) {
    let server: IServerPackage = {
        moralisServer: '',
        moralisAppId: '',
        networkRPC: '',
        networkChainId: 0,
        privateSaleContract: '',
        release: '',
        type: '',
        chainLookup: '',
    }
    switch (serverState) {
        case ServerState.MainNet : {
            server = {
                moralisServer: BSCMAIN_MoralisServer,
                moralisAppId: BSCMAIN_MoralisAppId,
                networkChainId: BSCMainNetworkChainID,
                networkRPC: BSCMainNetwork,
                privateSaleContract: PrivateSaleAddressMain,
                release: release,
                type: 'production',
                chainLookup: BSCSCANMain
            }
            break;
        }
        case ServerState.TestMainNet : {
            server = {
                moralisServer: BSCMAIN_MoralisServer,
                moralisAppId: BSCMAIN_MoralisAppId,
                networkChainId: BSCMainNetworkChainID,
                networkRPC: BSCMainNetwork,
                privateSaleContract: PrivateSaleAddressMain,
                release: release,
                type: 'development',
                chainLookup: BSCSCANMain
            }
            break;
        }
        case ServerState.TestNet : {
            server = {
                moralisServer: BSCTEST_MoralisServer,
                moralisAppId: BSCTEST_MoralisAppId,
                networkChainId: BSCTestNetworkChainID,
                networkRPC: BSCTestNetwork,
                privateSaleContract: PrivateSaleAddressTest,
                release: release,
                type: 'development',
                chainLookup: BSCSCANTest
            }
            break;
        }
        default : {
            console.error("Error finding networks!");
            Sentry.captureMessage("Error finding networks!");
        }
    }

    return server;

}
