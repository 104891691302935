import millify from "millify";

export function trillify (_value: string) {
    let value =0;
    if (_value === undefined)
    {
        value = parseInt("0", 10);
    } else {
    value = parseInt(_value, 10);
    }
    return millify(value , {precision: 3, lowercase: true});
}