import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import {MoralisProvider} from 'react-moralis';
import {
    getServerInfo, IServerPackage,
    ServerState
} from "./configs/settings";
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";

// SET NETWORK TYPE HERE
const version = "v1.0.4";
const serverState = ServerState.TestMainNet;
const serverInfo: IServerPackage = getServerInfo(serverState, version);

const firebaseConfigMain = {
    apiKey: "AIzaSyDPlXJERNX8Jd7K8Fm8XfZM8x0mcuCzCl0",
    authDomain: "pepperbirdfinance.firebaseapp.com",
    projectId: "pepperbirdfinance",
    storageBucket: "pepperbirdfinance.appspot.com",
    messagingSenderId: "430311518493",
    appId: "1:430311518493:web:d6da1ecc67d4df645bf1f4",
    measurementId: "G-M89WYD8RS0"
};

const firebaseConfigTest = {
    apiKey: "AIzaSyDPlXJERNX8Jd7K8Fm8XfZM8x0mcuCzCl0",
    authDomain: "pepperbirdfinance.firebaseapp.com",
    projectId: "pepperbirdfinance",
    storageBucket: "pepperbirdfinance.appspot.com",
    messagingSenderId: "430311518493",
    appId: "1:430311518493:web:2120b70954837b6f5bf1f4",
    measurementId: "G-G4VV6JTXK4"
};

function getFirebaseConfig() {
    let firebaseConfig: any;
    if (serverInfo.type == 'production') {
        firebaseConfig = firebaseConfigMain;
    } else {
        firebaseConfig = firebaseConfigTest;
    }

    return firebaseConfig;
}

const app = initializeApp( getFirebaseConfig());
const analytics = getAnalytics(app);


export const MoralisAppID = serverInfo.moralisAppId;
export const MoralisServer = serverInfo.moralisServer;
export const RPCNetwork = serverInfo.networkRPC;
export const NetworkChainID = serverInfo.networkChainId;
export const PrivateSaleContract = serverInfo.privateSaleContract;
export const Release = serverInfo.release;
export const Stage = serverInfo.type;
export const BSC_SCAN_URL = serverInfo.chainLookup;


Sentry.init({
    dsn: "https://89be5b32f9a74b68bc085464c3b0d9d7@o1179624.ingest.sentry.io/6291888",
    integrations: [new BrowserTracing()],
    release: Release,
    environment: Stage,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <MoralisProvider appId={MoralisAppID} serverUrl={MoralisServer}>
                <App/>
            </MoralisProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
