import { createMachine, assign, DoneInvokeEvent, StateMachine } from "xstate";

export enum PSStateActions {
    SUCCESS= 'SUCCESS',
    FAIL='FAIL',
    NEXT='NEXT'
}
export const privateSaleComponentMachine: StateMachine<any, any, any> = createMachine({
    id: "privateSaleMachine",
    initial: "initialize_state",
    states: {
        initialize_state: {
            // Handle Moralis initialization
            // set state var to display connect wallet
            on: {
                SUCCESS: "set_display_connectWallet_state",
                FAIL: "handle_error_state"
            }
        },

        credential_check_state: {
            // Logged In
            on: {
                SUCCESS: "set_user_connection_connected", // start loading data
                FAIL: "set_user_connection_not_connected",
            }

        },

        determine_component_inactive: {
            on: {
                INACTIVE: "set_display_inactive_state",
                ACTIVE: "determine_user_whitelisted_state"
            }
        },

        determine_component_state: {
            on: {
                INACTIVE: "set_display_inactive_state",
                PRESALE: "set_display_presale_state",
                SALE_ACTIVE: "set_display_active_state",
                POST_SALE: "set_display_post_sale_state",
                LAUNCHED: "set_display_launched_state",
            }
        },

        set_display_post_sale_state: {

        },

        set_display_launched_state: {
            on: {
                PRIMARY_BUTTON_CLICKED: "redeem_tokens_state",
            }
        },

        set_display_active_state: {
            on: {
                PRIMARY_BUTTON_CLICKED: "buy_tokens_state",
            }
        },

        redeem_tokens_state: {
            on: {
                SUCCESS: "load_public_data_state",
                FAILURE: "load_public_data_state"
            }
        },

        buy_tokens_state: {
            on: {
                BUY_SUCCESS: "load_public_data_state",
                BUY_ERROR: "load_public_data_state"
            }
        },
        set_display_presale_state: {
            on: {
                PRIMARY_BUTTON_CLICKED: "",
            }

        },

        determine_user_whitelisted_state: {
            on: {
                SUCCESS: "determine_component_state",
                FAIL:"connect_wallet_state",
            }
        },

        set_display_connectWallet_state: {
            on: {
                NEXT: "set_user_connection_not_verified_state"
            }
        },

        set_display_inactive_state: {

        },

        set_user_connection_not_verified_state: {
            on: {
                NEXT: "credential_check_state"
            }
        },

        set_user_connection_not_connected: {
            on: {
                NEXT: "connect_wallet_state"
            }
        },

        set_user_connection_connected: {
            on: {
                NEXT: "load_public_data_state"
            }
        },

        connect_wallet_state: {
            on: {

                CONNECT_METAMASK: "connect_metamask_state",
                CONNECT_WALLETCONNECT: "connect_walletconnect_state",
            }
        },

        connect_metamask_state: {
            on: {
                SUCCESS: "credential_check_state",
                FAIL: "credential_check_state"
            }

        },
        connect_walletconnect_state: {
            on: {
                SUCCESS: "credential_check_state",
                FAIL: "credential_check_state"
            }

        },


        load_public_data_state: {
            on: {
                SUCCESS: "determine_component_inactive",
                FAIL: "handle_error_state",
            }

        },



        presale_state: {
            //if start time


        },
        handle_error_state: {

        },
        notWhitelist_State: {


        }

    }
})