import type { NextPage } from "Next";
import React, { useEffect, useState } from "react";

interface CountDownProps {
    countDownDate: string;
}

const CountDownComponent: NextPage<CountDownProps> = (props) => {
    const [partyTime, setPartyTime] = useState(false);
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const {countDownDate} = props;



    useEffect(() => {
        const target = new Date(countDownDate);

        const interval = setInterval(() => {
            const now = new Date();
            const difference = target.getTime() - now.getTime();

            const d = Math.floor(difference / (1000 * 60 * 60 * 24));
            setDays(d);

            const h = Math.floor(
                (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            setHours(h);

            const m = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            setMinutes(m);

            const s = Math.floor((difference % (1000 * 60)) / 1000);
            setSeconds(s);

            if (d <= 0 && h <= 0 && m <= 0 && s <= 0) {
                setPartyTime(true);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    return (
        <ul>
            <li><span>{days}<em>days</em></span></li>
            <li><span>{hours}<em>hours </em></span></li>
            <li><span>{minutes}<em>minutes</em></span></li>
            <li><span>{seconds}<em>seconds</em></span></li>
        </ul>
    );
};

export default CountDownComponent;