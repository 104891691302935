import { Modal } from "react-bootstrap";

const VideoModal = (props: any) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.show}
      onHide={() => props.onHide()}
      className="video-modal"
    >
      <Modal.Body>
        <iframe
          width="100%"
          height="415"
          src="https://www.youtube.com/embed/vIcBSMSaJzE"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
        ></iframe>
      </Modal.Body>
    </Modal>
  );
};

export default VideoModal;
