
import React, { useEffect } from "react";
import Particles from "react-tsparticles";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";


ChartJS.register(ArcElement, Tooltip, Legend);

const Header = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      const canvas: any = document.querySelector("#tsparticles canvas");
      canvas.style.position = "unset";
      clearTimeout(timer);
    }, 500);
  }, []);

  const particlesInit = (main: any): any => {
    console.log(main);
  };

  const particlesLoaded = (container: any): any => {
    console.log(container);
  };

  const particleOptions: any = {
    background: {
      color: {
        value: "transparent",
      },
    },
    fpsLimit: 120,
    interactivity: {
      events: {
        onClick: {
          enable: true,
          mode: "push",
        },
        onHover: {
          enable: false,
          mode: "repulse",
        },
        resize: true,
      },
      modes: {
        bubble: {
          distance: 400,
          duration: 2,
          opacity: 0.8,
          size: 10,
        },
        push: {
          quantity: 4,
        },
        repulse: {
          distance: 200,
          duration: 1.4,
        },
      },
    },
    particles: {
      color: {
        value: "#ffffff",
      },
      links: {
        color: "#ffffff",
        distance: 150,
        enable: true,
        opacity: 0.5,
        width: 1,
      },
      collisions: {
        enable: true,
      },
      move: {
        direction: "none",
        enable: true,
        outMode: "bounce",
        random: false,
        speed: 1,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          area: 800,
        },
        value: 80,
      },
      opacity: {
        value: 0.5,
      },
      shape: {
        type: "circle",
      },
      size: {
        random: true,
        value: 2,
      },
    },
    detectRetina: true,
  };

  return (
    <div className="hero-main layout-2 white-sec">
      <div id="silver-tech-bg">
        <Particles
          className="particles-canvas"
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={particleOptions}
          style={{ position: "unset" }}
        />
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-6 hero-left">
            <div className="sec-title">
              <h1>
                Take Control of your Finances <span>Build your Nest!</span>
              </h1>
            </div>
            <p>
              “The greater the passive income you can build, the freer you will
              become.” ~Todd M. Fleming
            </p>
            <div className="hero-btns">
              <a href="http://www.t.me/pepperbird" className="btn">
                Join the Nest
              </a>
              <a href="#token" className="btn btn3">
                Token Structure
              </a>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 hero-right">
            <img src="assets/images/about-img-4.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
