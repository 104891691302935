import React, {useEffect, useState} from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import 'react-confirm-alert/src/react-confirm-alert.css';
import PrivateSaleComponent from "../components/privateSaleComponent/PrivateSaleComponent";
const PrivateSalePage:React.FC = () => {

 const userFunction = '';

    return (
        <div className="wrapper" id="top">
            <Navbar/>
            <div className="midd-container">
                <PrivateSaleComponent
                    privateSaleTitle={"Carolina Reaper"}
                />
                <div className="clear"/>
                <Footer/>
            </div>
        </div>


    );
};

export default PrivateSalePage;
