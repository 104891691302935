import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [offset, setOffset] = useState(false);

  const onScroll = () => {
    if (window.pageYOffset > 50) {
      setOffset(true);
    } else {
      setOffset(false);
    }
  };

  useEffect(() => {
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <header className={offset ? "fixed" : ""}>
      <div className="container">
        <div className="row">
          <div className="col-sm-4 logo">
            <a href="index.html" title="Pepperbird Finance">
              <img
                className="light"
                src="assets/images/logo-dark.png"
                alt="Pepperbird Finance"
              />
              <img
                className="dark"
                src="assets/images/logo-light2.png"
                alt="Pepperbird Finance"
              />
            </a>
          </div>
          <div className="col-sm-8 main-menu">
            {/* <div className="menu-icon">
      <span className="top"></span>
      <span className="middle"></span>
      <span className="bottom"></span>
    </div> */}
            <nav className="onepage">
              <ul>
                <li className="active">
                  <Link to="/">Home</Link>
                </li>
                <li><a href="#about">About</a></li>
                <li><a href="#token">token</a></li>
                <li><a href="#team">team</a></li>
                <li><a href="https://github.com/AnalytixAudit/Solidity/blob/main/Audit_Pepperbird.pdf"  target="_blank">Audit</a></li>
                <li><a href="https://whitepaper.pepperbird.finance/">Whitepaper</a></li>
                {/* <!--   <li><a href="#faq">faq</a></li> --> */}
                <li className="nav-btn">
                  <a href="#roadmap">roadmap</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
