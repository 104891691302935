import React, { Fragment, useEffect, useState } from "react";
import Particles from "react-tsparticles";
import { Chart as ChartJS, ArcElement, Tooltip, Legend,  } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import VideoModal from "../components/VideoModal";
import Header from "../components/Header";

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.defaults.plugins.legend.display = false;

const Home = () => {
  const [isVideoShow, setIsVideoShow] = useState(false);

  useEffect(() => {}, []);

  const chartOneData = {
    labels: ["Private Sale", "PreSale & Liquidity", "Operations", "Founders & Team"],
    datasets: [
      {
        label: "Initial Token Distribution",
        data: [14, 53, 15, 18],
        backgroundColor: [
          "#7d7d7d",
          "#fbbd18",
          "#5f9747",
          "#d47c0c",

        ],
        borderColor: [
          "#7d7d7d",
          "#fbbd18",
          "#5f9747",
          "#d47c0c",

        ],
        borderWidth: 1,
      },
    ],
  };

  const chartTwoData = {
    labels: ["Reflections", "Liquidity", "Charity", "Buy Back" , "Operations"],
    datasets: [
      {
        label: "Tokenomics",
        data: [6, 3, 1, 1, 1],
        backgroundColor: [
          "#fbbd18",
          "#d47c0c",
          "#7d7d7d",
          "#f6aa35",
          "#5f9747",
        ],
        borderColor: ["#fbbd18","#d47c0c", "#7d7d7d", "#f6aa35", "#5f9747"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="wrapper" id="top">
      <Navbar />
      <div className="midd-container">
        <Header />
        <div className="about-section style-2 p-tb" id="about">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-5 about-left">
                <div className="sec-title">
                  <h1>Pepperbird Finance</h1>
                  <h3>
                    Multiple reflections, hyper deflationary, ecommerce centric
                    and charity focus
                  </h3>
                </div>
              </div>
              <div className="col-md-7 about-left">
                <a
                  className="wow rotateIn fancybox-media"
                  onClick={() => setIsVideoShow(true)}
                >
                  <img
                    src="assets/images/play-btn-2.png"
                    alt=""
                    className="play-btn-img"
                  />
                </a>
                <VideoModal
                  show={isVideoShow}
                  onHide={() => setIsVideoShow(false)}
                />
                <h5 className="accent-color">
                  Hold Pepperbird Token in your wallet and earn passive income
                  rewards in WBNB, Y-5 Token and Reflecto Coin. The best part is
                  both Y-5 and Reflecto also give out rewards. Make your money
                  work for you
                </h5>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="empty-30"></div>
                <p className="lead">
                  Pepperbird Finance is a comprehensive financial solution
                  ecosystem built with our investors in mind. Its driving focus
                  is the development of utilities that creates consistent
                  passive income for all holders of Pepperbird Token. An
                  ecosystem with high yield staking and farming, a Lending and
                  borrowing platform and an integrated money management system.
                  Our goal is simple, real world financial solutions for real
                  world people. There is so much we have planned, 2022 is just
                  the beginning.
                </p>
                <p className="lead">Buy-Hold and Enjoy the Flight.</p>
                <a className="btn" href="https://whitepaper.pepperbird.finance">
                  White paper
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="benefits p-tb c-l">
          <div className="container">
            <div className="text-center">
              <h2 className="section-heading">
                Reasons to Love <span>Our Ecosystem</span>
              </h2>
            </div>
            <div className="benefits-box row">
              <div className="col-md-3">
                <div className="bf-icon">
                  <img src="assets/images/Time_1.png" alt="" />
                </div>
                <h3>Multiple Reflections</h3>
                <p>Reflections that earn you even more reflections</p>
              </div>
              <div className="col-md-3">
                <div className="bf-icon">
                  <img src="assets/images/Cloud-Based.png" alt="" />
                </div>
                <h3>NFT Boosters</h3>
                <p>
                  Buy and hold one of our NFT while staking and earn a higher
                  APY
                </p>
              </div>
              <div className="col-md-3">
                <div className="bf-icon">
                  <img src="assets/images/No-transaction-fees.png" alt="" />
                </div>
                <h3>Extra Income</h3>
                <p>5% of every Merch store sale goes to the reflection pool.</p>
              </div>
              <div className="col-md-3">
                <div className="bf-icon">
                  <img src="assets/images/Instant-operations.png" alt="" />
                </div>
                <h3>Charity Focus</h3>
                <p>
                  Give a little, help a lot. 1% of all transaction tax goes to
                  charity.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="team-section p-tb" id="team">
          <div className="container">
            <div className="team-member text-center">
              <div className="sec-title text-center">
                <h3>Our Leadership Team</h3>
              </div>
              <div className="sub-txt text-center">
                <div className="row">
                  <div className="col wow fadeInUp">
                    <div className="team">
                      <img src="assets/images/carmen_n.png" alt="team" />
                      <h4>Carmen</h4>
                      <span>Founder & CEO</span>
                    </div>
                    <div className="team-social">
                      <ul>
                        <li><a href="https://twitter.com/jeneneal" target="_blank"><i className="fab fa-twitter"></i></a></li>
                        <li><a href="https://www.linkedin.com/in/carmenjglover/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                        <li><a href="https://www.instagram.com/jenelove7" target="_blank"><i className="fab fa-instagram"></i></a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col wow fadeInUp" data-wow-delay="0.1s">
                    <div className="team">
                      <img src="assets/images/kareem_g.png" alt="team" />
                      <h4>Kareem</h4>
                      <span>CTO & Lead Developer</span>
                    </div>
                    <div className="team-social">
                      <ul>
                        <li><a href="https://twitter.com/thisiskareemg" target="_blank"><i className="fab fa-twitter" ></i></a></li>
                        <li><a href="https://www.linkedin.com/in/kareemglover/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                        <li><a href="https://www.instagram.com/thisiskareemg" target="_blank"><i className="fab fa-instagram"></i></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="roadmap-sec p-tb white-sec" id="roadmap">
            <div className="container">
              <div className="sec-title text-center">
                <h3>The Roadmap</h3>
              </div>
              <div className="sub-txt text-center">
                <p>Summary of 2022 Roadmap</p>
              </div>
              <div className="vertical-roadmap">
                <div className="roadmap-item done">
                  <div className="title">
                    <span>Phase One</span>
                    <h4>Research and Development </h4>
                  </div>
                  <div className="roadmap-text">
                    <p><del>Define the purpose of Pepperbird Finance and Token</del></p>
                    <p><del>Research, Identify, develop and test token structure</del></p>
                    <p>
                      <del>Comparison analysis of pre-selected tokens to maximize
                        reflections</del>
                    </p>
                    <p><del>Create social media accounts, website and Lite Paper</del></p>
                  </div>
                </div>
                <div className="roadmap-item active">
                  <div className="title">
                    <span>Preparing for Launch</span>
                    <h4>Marketing</h4>
                  </div>
                  <div className="roadmap-text">
                    <p><del>Website launch</del></p>
                    <p>Post launch and presale marketing campaign</p>
                    <p>Complete Reflection Calculator Dashboard</p>
                  </div>
                </div>
                <div className="roadmap-item">
                  <div className="title">
                    <span>Phase Two</span>
                    <h4>Launch Presale and PancakeSwap</h4>
                  </div>
                  <div className="roadmap-text">
                    <p>Private Sale</p>
                    <p>Marketing Campaign v2</p>
                    <p><del>Complete audit and KYC</del></p>
                    <p>Presale</p>
                    <p>PancakeSwap</p>
                    <p>Coin Marketcap Listing</p>
                    <p>Coin Gecko Listing</p>
                    <p>Onboarding New Hires</p>
                  </div>
                </div>
                <div className="roadmap-item">
                  <div className="title">
                    <span>Phase Three</span>
                    <h4>Staking and Farming dApp </h4>
                  </div>
                  <div className="roadmap-text">
                    <p>Custom reflection dashboard</p>
                    <p>Gasless dashboard</p>
                    <p>Implementing staking and farming</p>
                    <p>NFT Boosters</p>
                    <p>Launch Discord & Instagram</p>
                  </div>
                </div>
                <div className="roadmap-item">
                  <div className="title">
                    <span>1st Charity Donation</span>
                    <h4>Identify Partner Charities</h4>
                  </div>
                  <div className="roadmap-text">
                    <p>
                      1% of every transaction goes to charity. 0.5% to a charity
                      focus on outreach to Liberia, West Africa and other
                      African Nations. 0.5% to charities focus on veterans,
                      women and children
                    </p>
                    <p>Influencer Marketing</p>
                  </div>
                </div>
                <div className="roadmap-item">
                  <div className="title">
                    <span>Phase Four</span>
                    <h4>Development of the platform</h4>
                  </div>
                  <div className="roadmap-text">
                    <p>Team Expansion</p>
                    <p>
                      Merch Store (5% of all sales goes to the reflection pool)
                    </p>
                    <p>API Marketplace</p>
                    <p>2nd Charity Donation</p>
                    <p>
                      JenFin Fiat and Crypto Wallet (20% of membership fees goes
                      to reflection pool)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="token-sale p-tb" id="token">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="empty-45"></div>
                  <div className="sec-title text-center">
                    <h3>Token Structure</h3>
                    <div className="sub-txt mw-650 text-center">
                      <p>100 Trillion Total Supply | 12% Transaction Tax</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 wow zoomIn">
                  <div className="sale-chart-view">
                    <div className="doughnut">
                      <div className="doughnutChartContainer">
                        <Doughnut data={chartOneData} />
                      </div>
                      <div id="legend" className="chart-legend">
                        <ul className="doughnut-legend">
                          <li>
                            <span
                              className="doughnut-legend-icon"
                              style={{ backgroundColor: "#7d7d7d" }}
                            ></span>
                            <span className="doughnut-legend-text">
                              14% Private Sale
                            </span>
                          </li>
                          <li>
                            <span
                              className="doughnut-legend-icon"
                              style={{ backgroundColor: "#fbbd18" }}
                            ></span>
                            <span className="doughnut-legend-text">
                              53% Pre-Sale &amp; Liquidity
                            </span>
                          </li>
                          <li>
                            <span
                              className="doughnut-legend-icon"
                              style={{ backgroundColor: "#5f9747" }}
                            ></span>
                            <span className="doughnut-legend-text">
                              15% Operations
                            </span>
                          </li>
                          <li>
                            <span
                              className="doughnut-legend-icon"
                              style={{ backgroundColor: "#d47c0c" }}
                            ></span>
                            <span className="doughnut-legend-text">
                              18% Founders &amp; Team
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 wow zoomIn">
                  <div className="sale-chart-view">
                    <div className="doughnut">
                      <div className="doughnutChartContainer">
                        <Doughnut data={chartTwoData} />
                      </div>
                      <div id="legend" className="chart-legend">
                        <ul className="doughnut-legend">
                          <li>
                            <span
                              className="doughnut-legend-icon"
                              style={{ backgroundColor: "#fbbd18" }}
                            ></span>
                            <span className="doughnut-legend-text">
                              6% Reflections
                            </span>
                          </li>
                          <li>
                            <span
                              className="doughnut-legend-icon"
                              style={{ backgroundColor: "#d47c0c" }}
                            ></span>
                            <span className="doughnut-legend-text">
                              3% Liquidity
                            </span>
                          </li>

                          <li>
                            <span
                              className="doughnut-legend-icon"
                              style={{ backgroundColor: "#7d7d7d" }}
                            ></span>
                            <span className="doughnut-legend-text">
                              1% Charity
                            </span>
                          </li>
                          <li>
                            <span
                              className="doughnut-legend-icon"
                              style={{ backgroundColor: "#f6aa35" }}
                            ></span>
                            <span className="doughnut-legend-text">
                              1% Buy Back
                            </span>
                          </li>
                          <li>
                            <span
                              className="doughnut-legend-icon"
                              style={{ backgroundColor: "#5f9747" }}
                            ></span>
                            <span className="doughnut-legend-text">
                              1% Operations
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clear"></div>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
