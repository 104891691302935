import React from "react";
import {interpret, StateValue} from "xstate";
import {privateSaleComponentMachine, PSStateActions} from "./PrivateSaleComponentMachine";
import {initWeb3, Web3LoginType} from "../../functions/Web3Management";
import { isMetaMaskInstalled, isWalletConnected} from "../../functions/moralisFunctions";
import "./privateSaleSection.css";
import Moralis from "moralis";
import WalletConnectProvider from "@walletconnect/web3-provider";
import {formatBEP20Token} from "../../functions/formatTokenBalance";
import {IPrivateSale} from "../../interfaces/IPrivateSale";
import CountDown from "../CoundDownComponent";
import {trillify} from "../../functions/trillify";
import {Line} from "rc-progress";
import Slider from "@mui/material/Slider";
import {Alert, AlertTitle, Backdrop, Button, CircularProgress, Popover, Snackbar} from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {onClickUrl} from "../../functions/handleNewTab";
import * as Sentry from "@sentry/react";
import {BSC_SCAN_URL, NetworkChainID, PrivateSaleContract, RPCNetwork} from "../../index";
import BlockIcon from '@mui/icons-material/Block';

const privateSaleABI = require("../../abi/PepperBirdPrivateSale.json");

enum AlertType {
    Error = 400,
    Warning = 300,
    Info = 100,
    Success = 200
}

enum ComponentStates {
    UnMounted,
    Initial,
    ConnectWallet,
    PrivateSaleInactive,
    PreSale,
    Active,
    PostSale,
    Launched
}

enum UserStates {
    NotVerified,
    NotConnected,
    Connected,
    Connected_NotOnWhitelist,
    Connected_OnWhiteList
}

interface IAlertMessage {
    code?: AlertType,
    title?: string,
    message?: string,
    closeAction?: any,
}
interface IProps {
    privateSaleTitle: string;
}
interface IState {
    current: any;
    componentState: ComponentStates,
    userState: UserStates,
    currentUser: any,
    privateSaleData: IPrivateSale,
    minPurchase: number,
    vestingDate: string,
    isAWhiteLister: boolean,
    leftToDistribute: string,
    buyButtonText: string,
    userMaxBuy: number,
    errorBalanceExceeded: boolean
    walletBalance: any;
    address: string;
    availableRedemption: string,
    alertMessage: IAlertMessage,
    isAlertMessageOn: boolean,
    isBackDropActive: boolean,
    isWalletConnectActive: boolean,
}

class PrivateSaleComponent extends React.Component<IProps, IState> {

    ethers:any;
    provider:any;
    metaMaskInstalled: boolean;
    providerLogin: {metaMask: boolean, walletConnect: boolean}
    privateSaleData: IPrivateSale = {};
    privateSaleStartDate: string;
    signer:any;
    bnbToBuy = 0;
    launchDate: string;
    oneBNBtoPBT = 54_000_000_000;
    maxBuyPBT = 216_000_000_000;
    web3Provider: any;

    constructor(props: IProps) {
        super(props);
        this.metaMaskInstalled = false;
        this.providerLogin = {metaMask: false, walletConnect: false}
        this.privateSaleStartDate = "4/10/2022 23:59:59";
        this.launchDate = '5/30/2022 23:59:59';
        this.state = {
            alertMessage: {},
            isAlertMessageOn: false,
            current: privateSaleComponentMachine.initial,
            componentState: ComponentStates.UnMounted,
            userState: UserStates.NotVerified,
            currentUser: {},
            minPurchase: 0,
            vestingDate: "4/11/2025 03:18:37",
            isAWhiteLister: false,
            availableRedemption: "0",
            leftToDistribute: "40500000000",
            errorBalanceExceeded: false,
            buyButtonText: "Buy Tokens",
            userMaxBuy: 0,
            walletBalance: 100,
            address: "",
            isBackDropActive: false,
            isWalletConnectActive: false,
            privateSaleData: {
                privateSaleMinimumPurchase: this.privateSaleData.privateSaleMinimumPurchase,
                privateSaleDistributed: this.privateSaleData.privateSaleDistributed,
                privateSaleStatus: this.privateSaleData.privateSaleStatus,
                privateSaleAllocated: this.privateSaleData.privateSaleAllocated,
                privateSaleOnWhitelist: this.privateSaleData.privateSaleOnWhitelist,
                holderInfo: {
                    total: '0',
                    monthlyCredit: this.privateSaleData.holderInfo?.monthlyCredit,
                    paymentIntervalCredit: this.privateSaleData.holderInfo?.paymentIntervalCredit,
                    amountLocked: this.privateSaleData.holderInfo?.amountLocked,
                    nextPaymentUntil: this.privateSaleData.holderInfo?.nextPaymentUntil,
                    initial: this.privateSaleData.holderInfo?.initial,
                    payedInitial: this.privateSaleData.holderInfo?.payedInitial,
                }
            }
        }
    }

    service = interpret(privateSaleComponentMachine).onTransition(async (current) => {
            this.setState({current});
            console.log(current.value);
            await this.pageController(current.value);
        }
    );

    componentDidMount() {
        this.service.start();
        this.setState( {componentState: ComponentStates.Initial})
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {}

    componentWillUnmount() {
        this.service.stop();
    }

    computeAvailableRedemption() {
        let amount1 = 0;
        let amount2 = 0;
        let available: number;
        const amountLocked = parseInt(this.privateSaleData.holderInfo?.amountLocked!);
        const now = new Date();
        const secondsSinceEpoch = Math.round(now.getTime() / 1000);
        const nextIntervalDate = parseFloat(this.privateSaleData.holderInfo?.nextPaymentUntil!) * Math.pow(10, 18);
        const vestDate = this.getVestingDate(this.privateSaleData.holderInfo?.nextPaymentUntil!);
        const vestDateFiltered = new Date(vestDate);
        console.log('Date',now,vestDateFiltered);
        const paymentIntervalCredit = parseInt(this.privateSaleData.holderInfo?.paymentIntervalCredit!);
        const holderInitial = parseInt(this.privateSaleData.holderInfo?.initial!);

        if (amountLocked > 0 && now > vestDateFiltered) {
            console.log('past vesting time');
            amount1 = paymentIntervalCredit;
        } else { console.log('date not yet');}
        if (!this.privateSaleData.holderInfo?.payedInitial!) {
            amount2 = holderInitial;
        } else {console.log('inital already payed:', this.privateSaleData.holderInfo?.payedInitial!)}

        available = amount1 + amount2;
        console.log('available', available);
        this.setState({availableRedemption: available.toString()})

    }
    async initialize_state() {
        console.log("initializing component ");
        this.metaMaskInstalled = await isMetaMaskInstalled();
        await initWeb3().then(() => {
            console.log("initialize completed");
            this.ethers = Moralis.web3Library;
            this.service.send(PSStateActions.SUCCESS);
        });
    }

    async getWalletBalance(provider: any, address: String) {
        await this.provider.getBalance(address).then((balance: any) => {
            let formattedBalance = this.ethers.utils.formatEther(balance);
            this.setState({walletBalance: formattedBalance})
            console.log('moralis balance', formattedBalance);
        });
    }

    async areTokensStillAvailable(bnb: number) {
        let state:boolean = true;
        const signer = await this.provider.getSigner();
        const defaultConnection = new this.ethers.providers.JsonRpcProvider(RPCNetwork);
        const privateSaleContract = new this.ethers.Contract(PrivateSaleContract, privateSaleABI.abi, defaultConnection);
        const privateSaleFunction = privateSaleContract.connect(defaultConnection);
        const privateSaleAllocation = formatBEP20Token(await privateSaleFunction.privateSaleAllocation());
        const privateSaleDistribution = formatBEP20Token(await privateSaleFunction.privateSaleDistributed());
        this.setState({privateSaleData: {privateSaleAllocated : privateSaleAllocation}});
        this.setState({privateSaleData: {privateSaleDistributed : privateSaleDistribution}});

        const PBTAvailable = parseInt(privateSaleAllocation) - parseInt(privateSaleDistribution);
        const PBTToBuy = bnb * this.oneBNBtoPBT;

        if (PBTToBuy > PBTAvailable) {
            state = false;
            this.processMessageAlert({
                code: AlertType.Error,
                title: 'Private Sale Availability Error',
                message: 'There are not enough available tokens to fulfill request.',
                closeAction: this.closeAlert
            })
            Sentry.captureMessage("There are not enough available tokens to fulfill request");
        }

        return state;
    }

    getMaxBuy() {
        let userTotal: string;
        try {
            userTotal = this.privateSaleData.holderInfo?.total as string;
        } catch {
            userTotal = '0';
        }
        const value = parseInt(userTotal);
        const userAvailableToBuy = this.maxBuyPBT - value;
        const OneBNBPrice = this.oneBNBtoPBT;
        const availableLeftToBuy = parseInt(this.state.privateSaleData.privateSaleAllocated!) - parseInt(this.state.privateSaleData.privateSaleDistributed!)
        let maxBuy = 0;
        if (availableLeftToBuy < userAvailableToBuy) {
            maxBuy = (availableLeftToBuy  / OneBNBPrice);
        } else {
            maxBuy = (userAvailableToBuy / OneBNBPrice);
        }
        this.setState( {userMaxBuy: maxBuy});
        console.log("Max Buy set");
        let startingBuySetting: number;
        if (maxBuy >= this.state.minPurchase) {startingBuySetting = this.state.minPurchase} else {startingBuySetting = 0}
        this.getBuyTokensButton(startingBuySetting);
        this.service.send( "NEXT")
    }

    async getAddress() {
        let address = await this.signer.getAddress();
        this.setState({address: address})
    }

    async authWithMetaMask() {
        this.service.send("CONNECT_METAMASK");
        this.setState( {isBackDropActive: true});
        try {
            await Moralis.cleanup();
            const ethProvider = await Moralis.enableWeb3({ provider: "metamask" })
            // Prompt user for account connections
            await ethProvider.send("eth_requestAccounts", []);
            this.signer = ethProvider.getSigner();
            this.setState({address: await this.signer.getAddress()})
            this.provider = ethProvider;
            this.providerLogin.metaMask = this.provider.isMetaMask;
            console.log('metamask log in successful', this.provider)
            await this.getWalletBalance(this.provider, this.signer.getAddress());
            this.setState( {isBackDropActive: false});
            await this.processMessageAlert({
                code: AlertType.Success,
                title: 'Authentication Success',
                message: 'Address [' + this.state.address + '] connected.',
                closeAction: this.closeAlert
            })
            this.service.send(PSStateActions.SUCCESS);
        } catch (e: any) {
            this.setState( {isBackDropActive: false});
            console.log(e, "error")
            Sentry.captureException(e);
            this.processMessageAlert({
                code: AlertType.Error,
                title: 'Authentication Error',
                message: e.message,
                closeAction: this.closeAlert
            })
            this.service.send(PSStateActions.FAIL);
        }


    }

    async killWeb3Connections() {
         await this.web3Provider.disconnect();

        this.setState( {isWalletConnectActive: false})
        await this.processMessageAlert({
            code: AlertType.Success,
            title: 'WalletConnect Disconnected',
            message: 'Address [' + this.state.address + '] disconnected.',
            closeAction: this.closeAlert
        })
    }

    async checkWCAlreadyConnected() {
        const web3Provider = new WalletConnectProvider({
            rpc: {
                97: RPCNetwork,
                56: RPCNetwork,
            },
            chainId: NetworkChainID
        });
        let state:boolean = false;
        try {
            const ethProvider = new this.ethers.providers.Web3Provider(web3Provider);
            let address = '';
            if (ethProvider.provider.connected) {
                this.signer = ethProvider.getSigner();
                address = await this.signer.getAddress();
                this.setState({address: address});
                this.setState( {isWalletConnectActive: true})
                console.log("WC looks like we are connected")
                state = true;
            }
        } catch (e: any) {
            console.log("guess not connected: ");
            state = false;
        }

        return state;
    }

    async authWithWalletConnect() {
        this.service.send("CONNECT_WALLETCONNECT");

         this.web3Provider = new WalletConnectProvider({
            rpc: {
                97: RPCNetwork,
                56: RPCNetwork,
            },
            chainId: NetworkChainID
        });

        /** removed because was causing problems connecting back in
        try {
            await provider.close();
        } catch {

        }
         */
        this.setState( {isBackDropActive: true});
        try {
            await this.web3Provider.enable();
            const ethProvider = new this.ethers.providers.Web3Provider(this.web3Provider);
            if (ethProvider.provider.connected) {
                this.signer = ethProvider.getSigner();
                this.setState({address: await this.signer.getAddress()})
                this.provider = ethProvider;
                this.providerLogin.walletConnect = this.provider.provider.connected;
                console.log('walletconnect log in successful')
                await this.getWalletBalance(this.provider, this.signer.getAddress());
                this.setState( {isBackDropActive: false});
                await this.processMessageAlert({
                    code: AlertType.Success,
                    title: 'Authentication Success',
                    message: 'Address [' + this.state.address + '] connected.',
                    closeAction: this.closeAlert
                })
                this.setState( {isWalletConnectActive: true});
                this.service.send(PSStateActions.SUCCESS);
            } else {
                this.setState( {isBackDropActive: false});
                this.processMessageAlert({
                    code: AlertType.Error,
                    title: 'Authentication Error',
                    message: 'Unable to Connect',
                    closeAction: this.closeAlert
                })
                Sentry.captureMessage("Wallet Connect Authentication Failure");
                this.service.send(PSStateActions.FAIL);
            }
        } catch (e: any) {
            this.setState( {isBackDropActive: false});
            this.processMessageAlert({
                code: AlertType.Error,
                title: 'Authentication Error',
                message: e.message,
                closeAction: this.closeAlert
            })
            Sentry.captureException(e);
        }

    }


    async credential_check_state() {

        await isWalletConnected(this.providerLogin).then( async (data) => {
            const check = await this.checkWCAlreadyConnected();
            console.log(data);
            if (data.value || check) {
                console.log('Wallet Detected', data);
                this.provider = data.packet;
            } else {
                console.log('Wallet Not Detected')
            }
        })
    }

    async connect_wallet_state() {

    }

    handleExceedBalanceAlert() {
        this.processMessageAlert({
            code: AlertType.Warning,
            title: "Wallet Balance Alert",
            message: "Purchase Exceeds Current Wallet Balance.",
            closeAction: this.closeAlert
        })}

    processMessageAlert(alert: IAlertMessage) {
        this.setState( {alertMessage: {
                code: alert.code,
                title: alert.title,
                message: alert.message,
                closeAction: alert.closeAction,
            }});

        this.setState( {isAlertMessageOn: true});
    }

    async redeemTokens() {
        const userPrivateSaleContract = new this.ethers.Contract(PrivateSaleContract, privateSaleABI.abi, this.provider);
        const signer = await this.provider.getSigner(0);
        const userPrivateSaleFunction = await userPrivateSaleContract.connect(signer);
        this.setState({isBackDropActive: true});
        try {
            const handleRedemption = await userPrivateSaleFunction.userTokenRedemption();
            await handleRedemption.wait().then((value: any) => {
                if (value.confirmations > 0) {
                    console.log(value)
                    this.setState({isBackDropActive: false});
                    const alert: IAlertMessage = {
                        code: AlertType.Success,
                        title: "Transaction Status",
                        message: "Your Transactions was successful.",
                        closeAction: this.closeAlert
                    }
                    this.processMessageAlert(alert);
                    this.service.send("SUCCESS");

                }
            });
        } catch (e: any) {
            console.log(e);
            this.setState({isBackDropActive: false});
            const alert: IAlertMessage = {
                code: AlertType.Error,
                title: "Transaction Status",
                message: e.message,
                closeAction: this.closeAlert
            }
            Sentry.captureException(e);
            this.processMessageAlert(alert);
            this.service.send("FAIL");
        }
    }
    async buyTokens() {
        const userPrivateSaleContract = new this.ethers.Contract(PrivateSaleContract, privateSaleABI.abi, this.provider);
        const signer = await this.provider.getSigner(0);
        const userPrivateSaleFunction = await userPrivateSaleContract.connect(signer);
        const bnbValue: string = this.bnbToBuy.toString();
        const options = {value: this.ethers.utils.parseEther(bnbValue)}
        const address: string = await signer.getAddress();
        let balance = await this.provider.getBalance(address);
        let formattedBalance = this.ethers.utils.formatEther(balance);
        console.log(bnbValue);
        if ((formattedBalance <= bnbValue) || (!await this.areTokensStillAvailable(this.bnbToBuy))){
            this.service.send("NOT_ENOUGH_TOKENS")
            console.log("Not Enough Tokens");
        } else {
            this.setState({isBackDropActive: true});
            try {
                const registerBuy = await userPrivateSaleFunction.registerPrivateSale(address, options);
                await registerBuy.wait().then((value: any) => {
                    if (value.confirmations > 0) {
                        console.log(value)
                        const alert: IAlertMessage = {
                            code: AlertType.Success,
                            title: "Transaction Status",
                            message: "Your Transactions was successful.",
                            closeAction: this.closeAlert
                        }
                        this.processMessageAlert(alert);
                        this.service.send("BUY_SUCCESS");
                        this.setState({isBackDropActive: false});
                    }
                });
            } catch (e: any) {
                console.log(e);
                Sentry.captureException(e);
                this.setState({isBackDropActive: false});
                const alert: IAlertMessage = {
                    code: AlertType.Error,
                    title: "Transaction Status",
                    message: e.message,
                    closeAction: this.closeAlert
                }
                this.processMessageAlert(alert);
                // this.service.send("BUY_ERROR");
            }
        }
    }

    async handleButtonClick() {
        this.service.send("PRIMARY_BUTTON_CLICKED")
    }

    async displayDisconnectLink() {
        return <>
            <Button onClick={async () => {
                await this.handleButtonClick();
            }} variant="text"><BlockIcon sx={{ mr: 1 }} />
                Connected {this.state.address} </Button>
        </>
    }
    displayContractLink() {
        return <>
            <Button onClick={onClickUrl(BSC_SCAN_URL + '/address/'+ PrivateSaleContract)} variant="text"><OpenInNewIcon sx={{ mr: 1 }} />
                View Contract</Button>
            </>
    }
    displayBuyButton() {
        return  <>
            <div onClick={async () => {
            await this.handleButtonClick();
        }} className="hero-right-btn">
            <a className="btn btn4" href="#">{this.state.buyButtonText}</a>
        </div>
            <Slider
                size="small"
                min={this.state.minPurchase}
                max={this.state.userMaxBuy}
                defaultValue={0}
                step={.1}
                aria-label="Small"
                valueLabelDisplay="auto"
                onChange={this.onSliderValueChange}
            />
        </>
    }
    displayInactive() {
        return <>

                <h2>{this.props.privateSaleTitle}</h2>
                <h3><span>Not Yet Activated.</span> Please stay tuned.</h3>
            <div className="col-md-6">
            </div>
        </>
    }

    displayConnectWallet() {

        return <>
            <div className="col-md-6">
                <h2>{this.props.privateSaleTitle}</h2>
                <h4>PRIVATE SALE IS NOW OPEN</h4>
                {this.state.userState == UserStates.Connected_NotOnWhitelist && <h3>Please Connect Whitelisted Wallet</h3>}
                { this.metaMaskInstalled && <div onClick={async () => {
                    await this.authWithMetaMask();
                }} className="hero-right-btn">
                    <a className="btn btn4" href="#">Metamask</a>
                </div>}
                <div onClick={async () => {
                    await this.authWithWalletConnect();
                }} className="hero-right-btn">
                    <a className="btn btn4" href="#">Connect Wallet</a>
                </div>
                <div>Dapp Browser Recommended for Crypto.com Defi Wallet</div>
            </div>
            <div className="col-md-6">
            </div>

        </>
    }

    displayUserData() {
        return <><div className="container">
        <div className="text-center">
            <h2 className="section-heading">
                Your <span>Pepperbird Token </span>Distribution
            </h2>
            <h4>
                Wallet ID : {this.state.address}
            </h4>
        </div>
        <div className="benefits-box row">
            <div className="col-md-3">
                <div className="bf-icon">
                    <img src="assets/images/Time_1.png" alt="" />
                </div>
                <h3>Total For Distribution</h3>
                <p>{parseInt(this.state.privateSaleData.holderInfo?.total as string).toLocaleString()} PBIRD</p>
            </div>
            <div className="col-md-3">
                <div className="bf-icon">
                    <img src="assets/images/Cloud-Based.png" alt="" />
                </div>
                <h3>Distributed To You So Far</h3>
                <p>{parseInt(this.state.privateSaleData.holderInfo?.amountDistributed as string).toLocaleString()} PBIRD</p>
            </div>
            <div className="col-md-3">
                <div className="bf-icon">
                    <img src="assets/images/No-transaction-fees.png" alt="" />
                </div>
                <h3>Distribution Every 10th Day Post Launch</h3>
                <p>{parseInt(this.state.privateSaleData.holderInfo?.paymentIntervalCredit as string).toLocaleString()} PBIRD</p>
            </div>
            <div className="col-md-3">
                <div className="bf-icon">
                    <img src="assets/images/Instant-operations.png" alt="" />
                </div>
                <h3>Amount Locked For Interval Distribution</h3>
                <p>{parseInt(this.state.privateSaleData.holderInfo?.amountLocked as string).toLocaleString()} PBIRD</p>
            </div>
        </div>
        </div>
    </>
    }

    displayPreSale() {
        return <>
            <div className="col-md-6">
                <h2>Carolina Reaper</h2>
                <h4>Private Sale Event<span> will start in</span></h4>
                <div className="clock"><CountDown countDownDate={this.privateSaleStartDate}/>
                </div>
            </div>
            <div className="col-md-6">
                <div className="rang-slider-main">
                    <div className="rang-slider-toltip">
                        <span>Distributed <strong>{trillify(this.state.privateSaleData.privateSaleDistributed as string)} PBIRD</strong></span>
                        <span>Allocated <strong>{trillify(this.state.privateSaleData.privateSaleAllocated as string)} PBIRD</strong></span>
                    </div>
                    {this.getProgressBar(this.state.privateSaleData.privateSaleDistributed!,this.state.privateSaleData.privateSaleAllocated!)}
                </div>
            </div>

        </>
    }

    displayActive() {
        return <>
            <div className="col-md-6">
                <h2>Carolina Reaper</h2>
                <h4>Private Sale Event<span> will end in</span></h4>
                <div className="clock"><CountDown countDownDate={"4/29/2022 23:59:59"}/>
                </div>
                {this.state.userMaxBuy > 0 && this.displayBuyButton()}
                {this.state.userMaxBuy <= 0 && <div>Max Token Purchase Reached</div>}
                {this.state.errorBalanceExceeded && <Alert severity="error">Purchase Exceeds Current Wallet Balance!</Alert>}
            </div>
              <div className="col-md-6">
                <div className="rang-slider-main">
                    <div className="rang-slider-toltip">
                        <span>Reserved <strong>{trillify(this.state.privateSaleData.privateSaleDistributed as string)} PBIRD</strong></span>
                        <span>Allocated <strong>{trillify(this.state.privateSaleData.privateSaleAllocated as string)} PBIRD</strong></span>
                    </div>
                    {this.getProgressBar(this.state.privateSaleData.privateSaleDistributed!,this.state.privateSaleData.privateSaleAllocated!)}
                    <span>Your Total Purchase: <strong>{trillify(this.state.privateSaleData.holderInfo?.total as string)} PBIRD</strong></span>
                    <div><span>Your Wallet Balance: <strong>{parseFloat(this.state.walletBalance).toFixed(4)} BNB</strong></span></div>
                </div>
            </div>

        </>
    }

    displayPostSale() {
        return <>
            <div className="col-md-6">
                <h3>Carolina Reaper</h3>
                <h3><span>Claim Purchased Tokens on Launch</span></h3>
                <div className="clock"><CountDown countDownDate={this.launchDate}/>
                </div>
            </div>
            <div className="col-md-6">
                <div className="rang-slider-main">
                    <div className="rang-slider-toltip">
                        <span>Locked <strong>{trillify(this.state.privateSaleData.holderInfo?.amountLocked as string)} PBIRD</strong></span>
                        <span>Redeemable at Launch <strong>{trillify(this.state.availableRedemption as string)} PBIRD</strong></span>
                    </div>
                </div>
            </div>
        </>
    }

    displayLaunched() {
        return <>
            <div className="col-md-6">
                <h3>Carolina Reaper</h3>
                { (trillify(this.state.leftToDistribute as string) != "0") && <> <h4>Current Vesting<span> will end in</span></h4>
                    <div className="clock"><CountDown countDownDate={this.state.vestingDate}/>
                    </div> </>}
                {(this.state.availableRedemption != "0") && <>
                    <div onClick={async () => {
                        await this.handleButtonClick();
                    }} className="hero-right-btn">
                        <a className="btn btn4" href="#">Redeem!</a>
                    </div>
                </>}
                {(this.state.availableRedemption == "0") && <>
                    <div  className="hero-right-btn">
                        <h4>Nothing To Redeem Right Now</h4>
                    </div>
                </>}
            </div>
            <div className="col-md-6">
                <div className="rang-slider-main">
                    <div className="rang-slider-toltip">
                        <span>Locked <strong>{trillify(this.state.privateSaleData.holderInfo?.amountLocked as string)} PBIRD</strong></span>
                        <span>Redeemable Now <strong>{trillify(this.state.availableRedemption as string)} PBIRD</strong></span>
                    </div>
                    {this.getProgressBar(this.state.leftToDistribute!, this.state.privateSaleData.holderInfo?.total!)}
                    <div><span>Left To Distribute <strong>{trillify(this.state.leftToDistribute as string)} PBIRD</strong></span></div>
                    <div><span>Amount Distributed <strong>{trillify(this.privateSaleData.holderInfo?.amountDistributed!as string)} PBIRD</strong></span></div>
                </div>
            </div>
        </>
    }

    displayMessageAlert() {
        return <>
            <Popover open={this.state.isAlertMessageOn}
                    //  autoHideDuration={6000}
                      onClose={this.state.alertMessage.closeAction}
                      anchorReference="anchorPosition"
                      anchorPosition={{ top: 200, left: 400 }}
                      anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                      }}
            >
                <Alert onClose={this.state.alertMessage.closeAction} severity={this.getSeverityType(this.state.alertMessage.code!)} sx={{ width: '100%' }}>
                    <AlertTitle>{this.state.alertMessage.title}</AlertTitle>
                    <strong>{this.state.alertMessage.message}</strong>
                </Alert>
            </Popover>
        </>
    }

    closeAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState( {isAlertMessageOn: false});
    };

    getSeverityType(alert: AlertType) {
        switch (alert) {
            case AlertType.Error :
                return "error";

            case AlertType.Warning :
                return "warning";

            case AlertType.Info :
                return "info";

            case AlertType.Success :
                return "success";

            default :
                return "warning";
        }

    }

    getBuyTokensButton(_value: any) {
        const value = 54_000_000_000 * _value
        const valueText = "Buy " + trillify(value.toString()) + " PBIRD FOR " + _value + "BNB";
        this.bnbToBuy = _value;
        this.setState({buyButtonText: valueText});
    }

    isOverdrawn(_value:number ) {
        let check: boolean;
        check = _value > this.state.walletBalance;

        return check;
    }

    onSliderValueChange = (event: any) => {
        this.getBuyTokensButton(event.target.value);
        if (this.isOverdrawn(event.target.value)) {
            this.setState({errorBalanceExceeded: true})
            this.service.send("NOT_ENOUGH_TOKENS");
        } else {
            if (this.state.errorBalanceExceeded) {
                this.setState( {errorBalanceExceeded : false})
                this.service.send("NEXT");
            }
        }
    }
    getProgressBar(_value1: string, _value2: string) {
        const value = (parseInt(_value1 as string) / parseInt(_value2 as string)) * 100;
        return <Line percent={value} strokeWidth={4} strokeColor="#FFD700"/>;
    }

    getVestingDate(_dateInSeconds: any) {

        const seconds = parseInt(_dateInSeconds._hex.toString(), 16);
        console.log('epoch', seconds);
        let t = new Date(seconds * 1000);
        let stringDateValue = t.toLocaleString('en-US', {hour12: false})
        stringDateValue = stringDateValue.replaceAll(',', '');
        console.log(stringDateValue);
        return stringDateValue
    }


    isModeInactive() {
        let state = false;
        if (this.privateSaleData.privateSaleStatus == false && (parseInt(this.state.privateSaleData.privateSaleDistributed as string) == 0 )) {
            state = true;
        }
        return state;
    }

    isModePreSale() {
        let state = false;
        if (this.privateSaleData.privateSaleStatus == false && (parseInt(this.state.privateSaleData.privateSaleDistributed as string) == 0 )) {
            state = true;
        }
        return state;
    }

    isModeSaleActive() {
        let state = false;
        if (this.privateSaleData.privateSaleStatus == true) {
            state = true;
        }
        return state;
    }

    isModePostSale() {
        let state = false;
        if (this.privateSaleData.privateSaleStatus == false && this.privateSaleData.privateSaleLaunchMode == false && (parseInt(this.state.privateSaleData.privateSaleDistributed as string) > 0 )) {
            state = true;
        }
        return state;
    }

    isModeLaunched() {
        let state = false;
        if (this.privateSaleData.privateSaleLaunchMode == true) {
            state = true;
        }
        return state;
    }

    isWhiteListed() {
        console.log( this.privateSaleData.privateSaleOnWhitelist);
        return this.privateSaleData.privateSaleOnWhitelist;
    }


    determine_component_inactive() {
        if (this.isModeInactive()) {
            this.service.send("INACTIVE");
            console.log('inactive')
        } else {
            this.service.send("ACTIVE");
        }
    }

    async determine_user_whitelisted_state() {
        if (this.isWhiteListed()) {
            this.getMaxBuy();
            this.setState({userState: UserStates.Connected_OnWhiteList});
            this.service.send(PSStateActions.SUCCESS);
            console.log('whitelisted');
        } else {
            this.setState({userState: UserStates.Connected_NotOnWhitelist});
            this.setState({componentState: ComponentStates.ConnectWallet});
            this.processMessageAlert({
                code: AlertType.Warning,
                title: 'Whitelist Status',
                message: "Address is not on whitelist. Please try another.",
                closeAction: this.closeAlert
            })
            this.service.send(PSStateActions.FAIL);
            console.log('not whitelisted');
        }

    }
    async determine_component_state() {
        if (this.isModeInactive()) {
            this.service.send("INACTIVE");
            console.log('inactive')
        }
        if (this.isModePreSale()) {
            this.service.send("PRESALE")
            console.log('presale')
        }
        if (this.isModeSaleActive()) {
            this.service.send("SALE_ACTIVE")
            console.log('saleActive')
        }
        if (this.isModePostSale()) {
            this.service.send( "POST_SALE")
            console.log('postSale')
        }
        if (this.isModeLaunched()) {
            this.service.send("LAUNCHED");
            console.log('launched')
        }

    }

    async load_public_data_state() {
        console.log('running LoadPrivateSalePublicData')
        const signer = await this.provider.getSigner();
        console.log("Account:", await this.signer.getAddress());
        const userPrivateSaleContract = new this.ethers.Contract(PrivateSaleContract, privateSaleABI.abi, this.provider);
        const userPrivateSaleFunction = await userPrivateSaleContract.connect(signer);
        const defaultConnection = new this.ethers.providers.JsonRpcProvider(RPCNetwork);
        const privateSaleContract = new this.ethers.Contract(PrivateSaleContract, privateSaleABI.abi, defaultConnection);
        const privateSaleFunction = privateSaleContract.connect(defaultConnection);

        const privateSaleStatus = await privateSaleFunction.openPrivateSale();
        const privateSaleAllocation = formatBEP20Token(await privateSaleFunction.privateSaleAllocation());
        const privateSaleDistribution = formatBEP20Token(await privateSaleFunction.privateSaleDistributed());
        const privateSaleMinimumPurchase = formatBEP20Token(await privateSaleFunction.minimumPurchaseInBNB().then((value: string) => {
            this.setState({minPurchase: parseFloat(formatBEP20Token(value))});
            this.privateSaleData.privateSaleMinimumPurchase = formatBEP20Token(value);
        }));
        const privateSaleLaunchModeStatus = await privateSaleFunction.launchMode();
        let privateSaleIsWhitelisted = false;
        try {
            privateSaleIsWhitelisted = await userPrivateSaleFunction.addressOnWhiteList();
            if (privateSaleIsWhitelisted) {

            }
        } catch (e) {
            console.log("unable to verify privatesale status");
        } finally {

            this.privateSaleData = {
                privateSaleOnWhitelist: privateSaleIsWhitelisted,
                privateSaleAllocated: privateSaleAllocation,
                privateSaleStatus: privateSaleStatus,
                privateSaleDistributed: privateSaleDistribution,
                privateSaleMinimumPurchase: privateSaleMinimumPurchase,
                privateSaleLaunchMode: privateSaleLaunchModeStatus
            }

            if (this.privateSaleData.privateSaleOnWhitelist) {
                await userPrivateSaleFunction.holderInfo(signer.getAddress()).then(async (holderInfo: any) => {
                    this.privateSaleData.holderInfo = {
                        total: formatBEP20Token(holderInfo[0]),
                        monthlyCredit: formatBEP20Token(holderInfo[1]),
                        paymentIntervalCredit: formatBEP20Token(holderInfo[2]),
                        amountLocked: formatBEP20Token(holderInfo[3]),
                        nextPaymentUntil: holderInfo[4],
                        initial: formatBEP20Token(holderInfo[5]),
                        payedInitial: holderInfo[6],
                        amountDistributed: formatBEP20Token(holderInfo[7]),
                    }
                    const vestDate = this.getVestingDate(this.privateSaleData.holderInfo?.nextPaymentUntil!);
                    console.log('VestingDate:', vestDate, this.privateSaleData.holderInfo?.nextPaymentUntil!);
                    const leftToDistribute = (parseInt(this.privateSaleData.holderInfo?.total!) - parseInt(this.privateSaleData.holderInfo?.amountDistributed!)).toString();
                    console.log("sent", this.privateSaleData.holderInfo?.amountDistributed)
                    this.setState({vestingDate: vestDate})
                    this.setState({privateSaleData: this.privateSaleData});
                    this.setState({isAWhiteLister: true});
                    this.setState({leftToDistribute: leftToDistribute});
                    this.computeAvailableRedemption();
                });

            }
            this.setState({privateSaleData: this.privateSaleData})
            this.service.send(PSStateActions.SUCCESS);
        }
    }


    async set_display_inactive_state() {
        this.setState( {componentState: ComponentStates.PrivateSaleInactive});
       // this.service.send(PSStateActions.NEXT)

    }

    async set_display_active_state() {
        console.log("active state");
        this.setState( {componentState: ComponentStates.Active});
    }
    async set_display_presale_state() {
        console.log("presale state");
        this.setState( {componentState: ComponentStates.PreSale});
    }

    async set_display_postsale_state() {
        console.log("postsale state");
        this.setState( {componentState: ComponentStates.PostSale});
    }

    async set_display_connectWallet_state() {
        this.setState( {componentState: ComponentStates.ConnectWallet});
        this.service.send(PSStateActions.NEXT);
    }

    async set_display_launched_state() {
        console.log("launched state");
        this.setState( {componentState: ComponentStates.Launched})
    }
    async set_user_connection_not_verified_state() {
        this.setState( {userState: UserStates.NotVerified});
        this.service.send(PSStateActions.NEXT);
    }

    async set_user_connection_not_connected() {
        this.setState( {userState: UserStates.NotConnected});
         this.service.send(PSStateActions.NEXT);
    }

    async set_user_connection_connected() {
        this.setState( {userState: UserStates.Connected});
        this.service.send(PSStateActions.NEXT);
    }


    async pageController(stateValue: StateValue) {
        switch (stateValue) {
            case 'initialize_state': {
                await this.initialize_state();
                break;
            }
            case 'determine_component_inactive': {
                await this.determine_component_inactive();
                break;
            }
            case 'determine_component_state': {
                await this.determine_component_state();
                break;
            }
            case 'determine_user_whitelisted_state': {
                await this.determine_user_whitelisted_state();
                break;
            }
            case 'load_public_data_state': {
                await this.load_public_data_state();
                break;
            }
            case 'set_display_connectWallet_state': {
                await this.set_display_connectWallet_state();
                break;
            }
            case 'set_display_inactive_state': {
                await this.set_display_inactive_state();
                break;
            }
            case 'set_display_presale_state': {
                await this.set_display_presale_state();
                break;
            }
            case 'set_display_active_state': {
                await this.set_display_active_state();
                break;
            }
            case 'set_display_launched_state': {
                await this.set_display_launched_state();
                break;
            }
            case 'set_display_post_sale_state': {
                await this.set_display_postsale_state();
                break;
            }
            case 'set_user_connection_not_verified_state': {
                await this.set_user_connection_not_verified_state();
                break;
            }
            case 'set_user_connection_not_connected': {
                await this.set_user_connection_not_connected();
                break;
            }
            case 'set_user_connection_connected': {
                await this.set_user_connection_connected();
                break;
            }
            case'credential_check_state' : {
                await this.credential_check_state();
                break;
            }
            case 'buy_tokens_state' : {
                await this.buyTokens();
                break;
            }
            case 'redeem_tokens_state' : {
                await this.redeemTokens();
                break;
            }
            case 'connect_wallet_state' : {
                break;
            }
            default: {
                //statements;
                break;
            }
        }
    }

    render() {
        return (
            <div>
                {this.state.isAlertMessageOn && this.displayMessageAlert()}
                <div className="privateSale-main layout-2 white-sec">
                    <div className="container">
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={this.state.isBackDropActive} >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <div className="col-sm-10 wow fadeIn" data-wow-delay="0.5s" style={{paddingTop: 50}}>
                            {this.state.isWalletConnectActive && <>
                                <Button onClick={async () => {
                                    await this.killWeb3Connections();
                                }} variant="text"><BlockIcon sx={{ mr: 1 }} />
                                    Connected [{this.state.address}] </Button>

                            </>}
                            <div className="pre-sale-timer-outer">
                                <div></div>
                                <div className="pre-sale-timer style-2">
                                    <div className="row align-items-center ">
                                        {this.state.componentState == ComponentStates.ConnectWallet &&  this.displayConnectWallet()}
                                        {this.state.componentState == ComponentStates.PrivateSaleInactive &&  this.displayPreSale()}
                                        {this.state.componentState == ComponentStates.PreSale && this.displayPreSale()}
                                        {this.state.componentState == ComponentStates.Active && this.displayActive()}
                                        {this.state.componentState == ComponentStates.PostSale && this.displayPostSale()}
                                        {this.state.componentState == ComponentStates.Launched && this.displayLaunched()}
                                    </div>
                                    {this.displayContractLink()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.userState == UserStates.Connected_OnWhiteList && this.displayUserData()}
            </div>
        )}
}

export default PrivateSaleComponent;