import Moralis from "moralis";
import detectEthereumProvider from '@metamask/detect-provider';
import WalletConnectProvider from "@walletconnect/web3-provider";

interface IPacket {
    value?: any,
    message?: any,
    packet?: any,
    code?: any,
}

export const startMoralis: (arg: { serverUrl: string; appId: string }) => Promise<Moralis.User | undefined> = async ({ serverUrl, appId }) => {
    await Moralis.start({ serverUrl, appId });
    return await Moralis.User.current();
};



export async function isWalletConnected(_provider: any)  {
    const data: IPacket = {}
    console.log('provider', _provider);
    const provider = _provider;
        if (provider.metaMask || provider.walletConnect) {
            console.log('providerpass', provider);
            data.value = true;
            data.packet = provider;
        } else {
            console.log('providerfailed');
            data.value = false;
            data.message = 'Unable to detect window.ethereum';
        }

    return data;
}



export async function isMetaMaskInstalled() {
    let check = false;
    const provider = await detectEthereumProvider();
    if (provider) { check = true}
    return check;


}


export async function getCurrentUser() {
    return await Moralis.User.current();
}